import React, { Component, ErrorInfo } from "react";

export class TapErrorboundary extends Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error: any) {
    return {
      error,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error(error, info);
  }

  render() {
    if (this.state.error) {
      return <h1>404</h1>;
    }
    return this.props.children;
  }
}
