import React, { useState, useContext, useRef, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Link as ExternalLink } from "../elements/text/Link";
import { IoIosArrowDown } from "react-icons/io";
import { AnimatePresence, motion } from "framer-motion";
import styled from "@emotion/styled";
import { ThemeContext } from "../../theme/provider/theme-provider";
import { URLS } from "../../constants/constants";

const StyledList = styled(motion.ul)`
  position: absolute;
  right: 1rem;
  list-style: none;
  padding: 0;
  z-index: 10;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  overflow: hidden;
  margin: 0;
  background: white;
`;

const StyledListItem = styled.li`
  min-width: 10rem;
`;

const StyledTrigger = styled.button`
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform-origin: 50% 40% 0;
  display: flex;
  align-items: center;
  color: #888;
  justify-content: space-between;
`;

const StyledRouterLink = styled(NavLink)`
  padding: 0.5rem;
  display: block;
  text-decoration: none;
  color: ${(props: any) => props.colors.primary};
`;

const StyledExternalLink = styled(ExternalLink)`
  padding: 0.5rem;
  display: block;
`;

export interface MenuProps {}
export const Menu = (props: MenuProps) => {
  const [active, toggleActive] = useState(false);
  const conatinerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    toggleActive(false);
    const handleMouseClick = (event: MouseEvent) => {
      if (conatinerRef.current?.contains(event.target as any)) {
        return;
      }
      toggleActive(false);
    };
    document.addEventListener("mousedown", handleMouseClick);
    return () => document.removeEventListener("mousedown", handleMouseClick);
  }, [location]);

  const toggle = () => {
    toggleActive((active) => !active);
  };

  return (
    <div data-testid="menu" ref={conatinerRef} style={{ position: "relative" }}>
      <StyledTrigger onClick={toggle}>
        <motion.span
          style={{ display: "inline-block" }}
          animate={{
            rotateX: active ? "180deg" : "0deg",
          }}
        >
          <IoIosArrowDown
            size="2em"
            color={theme.colors.primary}
            style={{ verticalAlign: "bottom" }}
          />
        </motion.span>
      </StyledTrigger>
      <AnimatePresence>
        {active && (
          <StyledList
            initial="collapsed"
            animate={active ? "open" : "collapsed"}
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            exit="collapsed"
            transition={{
              duration: 0.4,
              ease: [0.04, 0.62, 0.23, 0.98],
              bounceDamping: 0,
            }}
          >
            <StyledListItem>
              <StyledRouterLink
                to="/backstage"
                {...theme}
                activeStyle={{ color: theme.colors.secondary }}
                exact
              >
                Backstage
              </StyledRouterLink>
            </StyledListItem>
            <StyledListItem>
              <StyledExternalLink href={URLS.feedback}>
                Feedback
              </StyledExternalLink>
            </StyledListItem>

            <StyledListItem>
              <StyledExternalLink href={URLS.insta}>
                Instagram
              </StyledExternalLink>
            </StyledListItem>
            <StyledListItem>
              <StyledExternalLink href={URLS.bmc}>
                Buy us a coffee
              </StyledExternalLink>
            </StyledListItem>
          </StyledList>
        )}
      </AnimatePresence>
    </div>
  );
};
