import React from "react";
import { keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import { useContext } from "react";
import { ThemeContext } from "../../theme/provider/theme-provider";
import { PATH } from "../../constants/constants";
import { Link, useLocation } from "react-router-dom";
import { useTracking } from "../../hooks/useTracking";
import { Menu } from "./Menu";
const rotate = keyframes`
20%, 100% {
  transform: rotate(360deg);
}
`;

const StyledHeader = styled.header`
  height: 4rem;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
  justify-content: space-between;
`;

const StyledRouterLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  color: ${(props: any) => props.colors.primary};
  -webkit-tap-highlight-color: transparent;
`;

const StyledLogo = styled(StyledRouterLink)`
  display: flex;
  align-items: center;

  & > span {
    margin-left: 0.5rem;
  }

  & > img {
    animation: ${rotate} 10s ease infinite;
  }
`;

interface headerProps {}
export const Header = (props: headerProps) => {
  useTracking();
  const location = useLocation();
  const { theme } = useContext(ThemeContext);
  return (
    <StyledHeader>
      <div>
        <StyledLogo to="/" {...theme} replace={location.pathname === "/"}>
          <img
            src={`${PATH.images}/icons/logo.svg`}
            width="36"
            height="36"
            alt="TAP logo"
          />
          <span>Let's TAP it</span>
        </StyledLogo>
      </div>
      <div>
        <Menu />
      </div>
    </StyledHeader>
  );
};
