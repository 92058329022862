import React, { ReactNode, ReactNodeArray } from "react";
import { Helmet } from "react-helmet";

export interface PageProps {
  title: string;
  children: ReactNode | ReactNodeArray;
}
export const Page = ({ title, children }: PageProps) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};
