import React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";

export interface SvgProps extends React.SVGProps<SVGSVGElement> {
  size?: string;
}

export const Svg = ({ size = "1.2em", ...props }: SvgProps) => (
  <svg
    height={size}
    width={size}
    viewBox="0 0 16 16"
    aria-hidden="true"
    focusable="false"
    css={{
      display: "inline-block",
      fill: "currentColor",
      lineHeight: 1,
      stroke: "currentColor",
      strokeWidth: 0,
    }}
    {...props}
  />
);
