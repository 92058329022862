// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState, useCallback, useContext } from "react";
import { DownArrow } from "../icons/down-arrow";
import { motion, ForwardRefComponent, HTMLMotionProps } from "framer-motion";
import styled from "@emotion/styled";
import { ThemeContext, Theme } from "../../../theme/provider/theme-provider";

const StyledScrollTopContainer = styled<
  ForwardRefComponent<HTMLButtonElement, HTMLMotionProps<"button">>,
  Theme
>(motion.button)`
  background-color: ${(props) => props.colors.primary};
  position: fixed;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 20px;
  bottom: 20px;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: 0;
    background-color: ${(props) => props.colors.primaryDark};
  }

  &:hover {
    background-color: ${(props) => props.colors.primaryLight};
  }

  &:active {
    background-color: ${(props) => props.colors.primaryDark};
  }
`;

const UpArrow = styled(DownArrow)`
  transform: rotate(180deg);
`;

export const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);

  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    const scrollListener = (e: Event) => {
      if (window.pageYOffset > 300) {
        setVisible(true);
      } else {
        setVisible(false);
      }
      return () => window.removeEventListener("scroll", scrollListener);
    };

    window.addEventListener("scroll", scrollListener);
  }, []);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <StyledScrollTopContainer
      {...theme}
      initial={{
        opacity: 0,
        translateY: 20,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
      }}
      onClick={scrollToTop}
    >
      <UpArrow size="1.4em" color={theme.colors.white} />
    </StyledScrollTopContainer>
  );
};
