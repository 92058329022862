export const PATH = {
  images: "/images",
  fonts: "/fonts",
};

export const URLS = {
  insta: "https://www.instagram.com/tap.prismo/",
  feedback:
    "https://docs.google.com/forms/d/e/1FAIpQLSf3SDeymLFxr8Zzfhlgcus2s-DDSHru6FdEPUpG5CDdBWimwQ/viewform",
  bmc: "https://www.buymeacoffee.com/tapprismo",
};
