import React from "react";
import { Fragment, useState } from "react";
import styled from "@emotion/styled";
import { useContext, DetailedHTMLProps, AnchorHTMLAttributes } from "react";
import { ThemeContext, Theme } from "../../../theme/provider/theme-provider";
import { useDeviceDetection } from "../../../hooks/useDeviceDetection";
import { motion, AnimatePresence } from "framer-motion";
import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import { CrossIcon } from "../icons/cross";

const StyledLink = styled.a<Theme>`
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
  ${(props) =>
    `color:${props.colors.primary};
    &:hover {
      color: ${props.colors.primaryLight};
    };
    &:active {
      color: ${props.colors.primaryDark};
    }`}
`;

const StyledPopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
`;

const StyledPopup = styled(motion.div)`
  margin-bottom: 1rem;
  position: fixed;
  border-bottom: 0;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.14);
  background-color: #fff;
  overflow: hidden;
  padding: 1rem;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

const Flex = styled.div`
display: flex; justify-content: flex-end; }
`;

const getHref = (text: string, subject: string) => {
  if (isEmail(text)) {
    return `mailto:${text}?subject=${subject}`;
  }
  if (isMobilePhone(text)) {
    return `sms:${text}?body=${subject}: `;
  }
  return text;
};

interface LinkProps
  extends DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  subject?: string;
}

export const Link = ({
  children,
  href = "",
  subject = "",
  ...props
}: LinkProps) => {
  const { theme } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const { isMobile } = useDeviceDetection();
  const link = getHref(href, subject);
  const alternate = !isMobile && href && link.startsWith("sms:");
  return (
    <Fragment>
      <StyledLink
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => {
          if (alternate) {
            e.preventDefault();
            setOpen((state) => !state);
          }
        }}
        href={link}
        {...props}
        {...theme}
      >
        {children}
      </StyledLink>
      <AnimatePresence>
        {open && (
          <StyledPopupContainer>
            <StyledPopup
              initial={{
                scale: 0,
                top: "50%",
                left: "50%",
                translateY: "-50%",
                translateX: "-50%",
                maxWidth: "600px",
              }}
              animate={{
                scale: 1,
              }}
              exit={{ opacity: 0, scale: 0 }}
            >
              <Flex>
                <StyledButton onClick={() => setOpen(false)}>
                  <CrossIcon />
                </StyledButton>
              </Flex>
              <div>{`Drop a message to ${href}`}</div>
            </StyledPopup>
          </StyledPopupContainer>
        )}
      </AnimatePresence>
    </Fragment>
  );
};
