/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { ReactElement } from "react";

const iconStyles = css`
  color: hsl(0, 0%, 80%);
  display: inline-block;
  transition: color 150ms;
  box-sizing: border-box;
  &:hover {
    color: hsl(0, 0%, 60%);
  }
`;

export const Icon = ({ children }: { children: ReactElement }) => (
  <div css={iconStyles}>{children}</div>
);
