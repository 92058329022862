import React, { useContext } from "react";
import styled from "@emotion/styled";
import { ThemeContext, Theme } from "../theme/provider/theme-provider";
import { PATH } from "../constants/constants";
import { Page } from "../components/common/Page";

const StyledNotFoundContainer = styled.div`
  max-width: 1080px;
  margin: auto;
  padding: 0 1rem;
  transition: 0.4s all ease;
  text-align: center;
`;

const StyledButton = styled.a<Theme>`
  background: none;
  text-decoration: none;
  ${(props) => `
  border: 1px solid ${props.colors.primary};
  color: ${props.colors.white};
  background-color:${props.colors.primary};
  &:hover{
    border: 1px solid ${props.colors.primaryLight};
    background-color:${props.colors.primaryLight};
    cursor:pointer;
  }
  &:active,&:visited,&:focus {
    outline:none;
  }
  &:focus{
    border: 2px solid ${props.colors.primaryDark};
  }
  &:active{
    border: 1px solid ${props.colors.primaryDark};
    background-color:${props.colors.primaryDark};
  }
  `}
  padding: 0.5rem;
  border-radius: 3px;
  min-width: 300px;
  font-weight: bold;
`;

const StyledSection = styled.section`
  margin-top: 2rem;
  margin-bottom: 3rem;

  & > h1 {
    font-weight: 200;
    max-width: 800px;
    margin: 0.5rem auto;

    @media screen and (max-width: 479px) {
      font-size: 1.1rem;
    }
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 359px) {
    margin-bottom: 2.5rem;
  }
`;

const ResposiveImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

const ImageContainer = styled.section`
  @media screen and (min-width: 768px) {
    min-height: 20rem;
    max-width: 100%;
  }
`;

export interface NotFoundProps {}
export const NotFound = (props: NotFoundProps) => {
  const { theme } = useContext(ThemeContext);
  return (
    <Page title="404 - Not found">
      <StyledNotFoundContainer>
        <ImageContainer>
          <ResposiveImage src={`${PATH.images}/404/404.svg`} />
        </ImageContainer>
        <StyledSection>
          <h1>
            Archeologist and paleontologists spend their whole lives looking for
            things that no longer exist.
          </h1>
          <h1>Now you are, too. How is that going for you?</h1>
        </StyledSection>
        <StyledButton href="/" {...theme}>
          Go back and TAP
        </StyledButton>
      </StyledNotFoundContainer>
    </Page>
  );
};
