import React from "react";
import { createContext, useReducer } from "react";

const baseColors = [
  {
    primary: "#FF6584",
    primaryLight: "#ff7896",
    primaryDark: "#d84064",
  },
  {
    primary: "#7F77FE",
    primaryLight: "#b5a6ff",
    primaryDark: "#474bca",
  },
  {
    primary: "#FE7F77",
    primaryLight: "#ffb1a6",
    primaryDark: "#c74f4b",
  },
  {
    primary: "#FEDF77",
    primaryLight: "#ffffa8",
    primaryDark: "#c9ad48",
  },
];

const commonColors = {
  white: "#fff",
  link: "#0074D9",
  secondary: "#64617d",
};

export interface Theme {
  colors: {
    primaryLight: string;
    primary: string;
    primaryDark: string;
    white: string;
    link: string;
    secondary: string;
  };
  meta: {
    refreshCount: number;
  };
}

export interface ThemeState {
  theme: Theme;
  dispatch: (action: Action) => void;
}

export enum ActionType {
  changeTheme = "CHANGE_THEME",
  refreshTheme = "REFRESH_THEME",
}

export interface Action {
  type: ActionType;
  payload?: any;
}

const InitialState = {
  theme: {
    colors: {
      primary: "",
      primaryLight: "",
      primaryDark: "",
      white: "",
      link: "",
      secondary: "",
    },
    meta: {
      refreshCount: 0,
    },
  },
  dispatch: (action: Action) => ({}),
};

export const ThemeContext = createContext<ThemeState>(InitialState);

export const ThemeProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer((state: Theme, action: Action) => {
    switch (action.type) {
      case ActionType.refreshTheme:
        return {
          ...state,
          meta: {
            ...state.meta,
            refreshCount: state.meta.refreshCount + 1,
          },
        };
      default:
        return state;
    }
  }, getTheme());

  return (
    <ThemeContext.Provider value={{ theme: state, dispatch }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const getTheme = (): Theme => {
  /*
  Math.round(Math.random() * (baseColors.length - 1)); for randomizing colors
  */
  const colorIndex = 0;
  return {
    colors: {
      ...baseColors[colorIndex],
      ...commonColors,
    },
    meta: {
      refreshCount: 0,
    },
  };
};
