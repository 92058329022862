import React, { Suspense } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { Header } from "../components/common/header";
import { ScrollToTop } from "../components/elements/scroll/scroll-top";
import { NotFound } from "./NotFound";

const Backstage = React.lazy(() => import("./Backstage"));
const Main = React.lazy(() => import("./Main"));
const Artists = React.lazy(() => import("./Artists"));
const ArtistProfile = React.lazy(() => import("./ArtistProfile"));

export const Root = () => {
  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <Suspense fallback={<div />}>
        <Switch>
          <Route exact path="/backstage">
            <Backstage />
          </Route>
          <Route exact path="/artists">
            <Artists />
          </Route>
          <Route exact path="/404">
            <NotFound />
          </Route>
          <Route path="/:userName">
            <ArtistProfile />
          </Route>
          <Route exact path="/">
            <Main />
          </Route>
          <Redirect to="/404" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
