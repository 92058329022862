import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Credits:
 * https://medium.com/javascript-in-plain-english/google-analytics-with-react-router-and-hooks-16d403ddc528
 */

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      config: { page_path: string }
    ) => void;
    ga: any;
  }
}

export const useTracking = (
  trackingId: string | undefined = process.env.REACT_APP_GA_MEASUREMENT_ID
) => {
  const location = useLocation();
  useEffect(() => {
    if (!window.gtag) return;
    if (!trackingId) {
      console.log(
        "Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`."
      );
      return;
    }

    window.gtag("config", trackingId, { page_path: location.pathname });
  }, [trackingId, location]);
};
