import React from "react";
import "./polyfills";
import "flexboxgrid";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { globalStyles } from "./theme/global";
import { SWRConfig } from "swr";
import { Global } from "@emotion/core";
import { Request } from "./api/axios";
import { TapErrorboundary } from "./components/error/error-boundary";
import { ThemeProvider } from "./theme/provider/theme-provider";
import { Root } from "./pages";

export const App = () => {
  return (
    <ThemeProvider>
      <SWRConfig
        value={{
          fetcher: (url: string) => Request.get(url).then((r) => r.data),
        }}
      >
        <Global styles={globalStyles} />
        <TapErrorboundary>
          <Root />
        </TapErrorboundary>
      </SWRConfig>
    </ThemeProvider>
  );
};
