import { useEffect, useState } from "react";

const mobileAgentList = /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i;

export const useDeviceDetection = () => {
  const [isMobile, setMobile] = useState(false);
  useEffect(() => {
    const userAgent =
      (typeof window !== "undefined" && window.navigator.userAgent) || "";
    const isMobile = !!userAgent.match(mobileAgentList);
    setMobile(isMobile);
  }, []);
  return { isMobile };
};
