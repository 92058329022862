import { css } from "@emotion/core";
import { PATH } from "../constants/constants";
// variable font
const baseFont = "Quicksand/Quicksand-VariableFont_wght.ttf";

// static font for fallback

const baseFontNormal = "Quicksand/static/Quicksand-Regular.ttf";
const baseFontLight = "Quicksand/static/Quicksand-Light.ttf";
const baseFontBold = "Quicksand/static/Quicksand-SemiBold.ttf";

export const globalStyles = css`
  @supports (font-variation-settings: "wght" 200) {
    @font-face {
      font-family: "Quicksand";
      src: url(${PATH.fonts}/${baseFont});
    }
  }

  @supports not (font-variation-settings: "wght" 200) {
    @font-face {
      font-family: "Quicksand";
      src: url(${PATH.fonts}/${baseFontNormal});
      font-style: normal;
      font-weight: 400;
    }

    @font-face {
      font-family: "Quicksand";
      src: url(${PATH.fonts}/${baseFontLight});
      font-style: normal;
      font-weight: 200;
    }

    @font-face {
      font-family: "Quicksand", sans-serif;
      src: url(${PATH.fonts}/${baseFontBold});
      font-style: normal;
      font-weight: 600;
    }
  }

  html,
  body {
    padding: 0;
    margin: 0;
    color: #808080;
    font-family: "Quicksand", sans-serif;
    height: 100vh;

    input {
      font-size: 1em;
      color: #808080;
    }
  }

  #root {
    height: 100vh;
    position: relative;
  }

  * {
    font-family: "Quicksand", sans-serif;
  }
`;
